import request from '@/plugins/axios.config'

export function getBodyCheckList ({  hzmc, zdytjbh, deptStatus,deptAuth, startDate, endDate, pageNo, limit }) {
  return request({
    url: '/health/page/check',
    data: {
      pageNo, limit,
      entity: {
        hzmc,  zdytjbh,  deptStatus,deptAuth, startDate, endDate
      }
    }
  })
}

export function deleteBodyCheck ({ id}) {
  return request({
    url: `/health/delete/${id}`,
    type: 'delete'
  })

}

export function getDoctorList ({ pageNo, limit,doctor_name, doctor_department }) {
  return request({
    url: '/doctor/page/list',
    data: {
      pageNo, limit,
      entity: {
        doctor_name, doctor_department
      }
    }
  })

}

export function exportWord ({ id }) {
  console.log("BB");
  console.log(id);
  return request({
    url: `/health/ExportWord`,
    type: 'download',
    params: {
      id
    }
  })
}
export function exportWordZip ( ids ) {
  console.log(ids);
  return request({
    url: `/health/exportWordZip/`,
    type: 'download',
    data: {
      ids
    }
  })
}

export function excelExport ({ hzmc, hzsfz, lrys, lrdw, startDate, endDate }) {
  return request({
    url: `/health/excel/export`,
    type: 'download',
    data: {
      hzmc, hzsfz, lrys, lrdw, startDate, endDate
    }
  })
}
