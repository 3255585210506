<template>
  <el-card
    shadow="never"
    v-loading="loading"
    class=""
    style="min-height: 70vh"
  >
        <div class="mb16 flex_center">
          <div class="flex_auto flex">
            <el-input
              class="w200px mr16"
              v-model="searchForm.hzmc"
              placeholder="患者名称"
              @blur="search"
            ></el-input>
            <el-input
              class="w200px mr16"
              v-model="searchForm.zdytjbh"
              placeholder="体检报告编号"
              @blur="search"
            ></el-input>
            <el-select class="mr16" v-model="searchForm.deptStatus"  @change="search">
              <el-option value='COMPLETED' label="已完成" >已完成</el-option>
              <el-option value='INCOMPLETE' label="未完成" >未完成</el-option>
            </el-select>
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>

          </div>
          <div class="flex_none tr">
            <el-button type="success"
                       class="left"
                       round
                       icon="el-icon-download"
                       size="small"
                       @click="getZip()"
            >批量导出</el-button>
            <el-button
                class="mr16 ml16"
                type="primary"
                round
                icon="el-icon-search"
                @click="search"
            >查询</el-button>
            <router-link to="/detail" custom>
              <el-button type="primary" round icon="el-icon-edit-outline">新建</el-button>
            </router-link>
          </div>
    <!--      <div class="flex_none tr">-->
    <!--        <el-button-->
    <!--          round-->
    <!--          type="success"-->
    <!--          icon="el-icon-sold-out"-->
    <!--          @click="onExport"-->
    <!--          >Excel导出</el-button-->
    <!--        >-->
    <!--      </div>-->
        </div>
        <el-table :data="tableData" style="width: 100%"  height="480"
                  ref="reportTable"
                  @row-dblclick="handleDetail"
                  @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column prop="hzmc" label="患者名称" width="180">
          </el-table-column>
          <el-table-column prop="hzxb" label="患者性别" width="180">
          </el-table-column>
          <el-table-column prop="tjrq" label="体检日期" width="240"> </el-table-column>
          <el-table-column prop="lrys" label="录入医生" width="240"> </el-table-column>
          <el-table-column prop="zdytjbh" label="自定义体检编号" width="140"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <!-- <router-link :to="{ path: '/detail', query: { id: scope.row.id + '' }}"  custom> -->
                <el-button type="primary"
                           class="left"
                           round
                           icon="el-icon-s-order"
                           size="small"
                           @click="wordOnExport(scope.row)"
                >Word导出</el-button>
              <el-button class="center mr8"
                         type="text"
                         size="small"
                         @click="handleDetail(scope.row)"
                >编辑</el-button
              >
              <!-- </router-link> -->
              <el-popconfirm
                title="确定删除吗？"
                @confirm="handleDelete(scope.row)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="tr mt16">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNo"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
  </el-card>
</template>

<script>
import {getBodyCheckList, deleteBodyCheck, exportWord, excelExport, exportWordZip} from "@/http/report/list";
import moment from "moment";
import {Auth_All} from "@/constant";

export default {
  name: "List",
  components: {},
  props: {
    props: {
      type: Object, default: () => {
      }
    },
  },
  data() {
    return {
      deptAuth: Auth_All,
      loading: false,
      tableData: [],
      searchForm: {
        hzmc: "",
        zdytjbh: "",
        date: [],
        deptStatus:"INCOMPLETE",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pageNo: 1,
      limit: 20,
      total: 0,
      id: 0,
      ids:[],
    };
  },
  mounted() {
    this.deptAuth = this.$store.state.userInfo.deptAuth;
    this.setTime();
    this.getList();
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.reportTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.reportTable.clearSelection();
      }
    },
    handleSelectionChange(val){
      console.log(val);
      this.ids = [];
      val.forEach(item => {
        this.ids.push(item.id);
      })
      console.log(this.ids);
    },

    setTime() {
      const now = new Date();
      const start = moment().subtract(29, "days").toDate();
      this.searchForm.date = [start, now];
    },
    // TODO 失去焦点-> 输入查询条件后，点击事件会出发查询，导致功能按钮第一次被点击时无法正常工作
    search() {
      this.pageNo = 1;
      this.total = 1;
      this.getList();
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getList();
    },
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    getParams() {
      const format = "YYYY-MM-DD hh:mm:ss";
      const startDate = moment(this.searchForm.date[0]).format(format);
      const endDate = moment(this.searchForm.date[1]).format(format);
      const {hzmc, zdytjbh, deptStatus} = this.searchForm;
      const deptAuth = this.deptAuth;
      console.log("deptAuth:",deptAuth);
      const {pageNo, limit} = this;
      const data = {hzmc, zdytjbh, deptStatus,deptAuth, startDate, endDate, pageNo, limit};
      return data;
    },
    async getList() {
      if (this.loading) return;
      this.loading = true;
      getBodyCheckList(this.getParams())
          .then((res) => {
            if (res.success) {
              const result = res.result || {};
              this.tableData = result.records;
              this.total = result.total * 1;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async handleDelete(row) {
      this.$loading = true;
      deleteBodyCheck({id: row.id})
          .then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getList();
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async handleEdit(row) {
      this.$router.replace({
        url: "/list",
        query: {id: row.id},
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: '/detail',
        query: {id: row.id + ''},
      })
    },
    wordOnExport(row) {
      if (this.loading) return;
      this.loading = true;
      exportWord({id: row.id})
          .then((res) => {
            if (res.success) {
              this.$message.success("导出成功");
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
    async getZip(){
      console.log("getZip");
      console.log(this.ids);
      if (this.loading) return;
      this.loading = true;
      await exportWordZip(this.ids)
          .then((res) => {
            if (res.success) {
              this.$message.success("导出成功");
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },

    async onExport() {
      if (this.loading) return;
      this.loading = true;
      excelExport(this.getParams())
          .then((res) => {
            if (res.success) {
              this.$message.success("导出成功");
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style lang="less">
</style>
